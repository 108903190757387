<template>
  <main class="mr-4 md:mr-0 mt-6">
    <div class="flex items-center justify-between">
      <div>
        <h2 class="text-xs">Settings</h2>
        <h1 class="font-medium mt-2">Users and Roles</h1>
      </div>
      <div>
        <!-- <trac-button>
          <div class="px-4">Create Role</div>
        </trac-button> -->
      </div>
    </div>
    <div
      class="mt-16 w-full grid grid-cols-1 md:grid-cols-2 mb-4 col-gap-16 max-w-2xl justify-between mx-auto"
    >
      <div
        class="new-white pb-5 pt-10 px-10 rounded-md flex flex-col justify-center items-center"
      >
        <img src="./../../assets/svg/users-icon.svg" width="100" alt="" />
        <div class="mt-6 font-medium uppercase text-sm">manage users</div>
        <div class="mt-5 text-center text-xs">
          Manage user accounts on your <br />
          system
        </div>
        <div class="mt-8 pb-8">
          <trac-button data-test="manage-users" @button-clicked="$router.push({ name: 'UserSettings' })">
            <div class="px-4">MANAGE USERS</div>
          </trac-button>
        </div>
      </div>
      <div
        class="new-white pb-5 pt-10 px-10 rounded-md flex flex-col justify-center items-center"
      >
        <img src="./../../assets/svg/roles-icon.svg" width="100" alt="" />
        <div class="mt-6 font-medium uppercase text-sm">manage roles</div>
        <div class="mt-5 text-center text-xs">
          Manage user roles on your <br />
          system
        </div>
        <div class="mt-8 pb-8">
          <trac-button
          data-test="manage-roles"
            @button-clicked="$router.push({ name: 'ExistingRoles' })"
          >
            <div class="px-4">MANAGE ROLES</div>
          </trac-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {

}
</script>

<style>
.new-white {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
</style>